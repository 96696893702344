@mixin opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-dodo {
  color: #ffffff;
  background-color: #ff6900;

  &:hover {
    color: #ffffff;
    background-color: #e55f00;
  }
}

.nowrap {
  white-space: nowrap;
}

.text-white {
  color: #fff !important;
}

.max-300 {
  max-width: 300px;
}

.ml-20 {
  margin-left: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

@media (min-width: 768px){
  .modal-dialog {
    width: 550px;
    margin: 150px auto;
  }
}

.data-table-cell {
  text-align: right;
  padding-right: 30px !important;
}

.vault-header {
  h2 {
    margin-top: 10px;
  }
}

.vault-sub-header {
  font-size: 16px;
}

.cash-header {
  a {
    color: #dd4b39;
    font-size: 60%;
    margin-left: 20px;

    &:hover {
      @include opacity(.5);
    }
  }

  span {
    font-size: 80%;
  }
}

.cash-left-pad {
  margin-left: 20px;
}

.cash-col-info {
  font-size: 16px;

  h1 {
    margin-bottom: 0;
    span {
      color: #dd4b39;
      font-size: 60%;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.cash-col-table {
  font-size: 16px;

  th.cc-dodo {
    border-right: 1px solid #f4f4f4;
    text-align: right;
    padding: 8px 15px 8px 0;
  }

  th.cc-z {
    padding: 8px 0 8px 15px;
    vertical-align: middle;
  }

  td.cc-name {
    padding: 8px 0;
    vertical-align: middle;
    width: 220px;
  }

  td.cc-dodo-revenue {
    border-right: 1px solid #f4f4f4;
    text-align: right;
    padding: 8px 15px 8px 0;
    vertical-align: middle;
    width: 100px;
  }

  td.cc-edit-revenue {
    padding: 8px 0 8px 15px;
    vertical-align: middle;
    width: 100px;
  }

  td.cc-edit-cancel {
    padding: 8px 0;
    vertical-align: middle;
    width: 85px;
  }

  td.cc-diff {
    padding: 8px 0;
    vertical-align: middle;
    width: 120px;
  }

  .cash-input {
    display: inline;
    width: 50px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 100%;
    padding: 0;
  }
}

.collapsed-visible {
  display: none;
}

.collapsed-box {
  .collapsed-visible {
    display: block;
  }

  .collapsed-hidden {
    display: none;
  }
}

.swal2-checklist {
  text-align: left;

  .swal2-checkbox {
    display: flex;
    justify-content: left;
  }
}

.audit-log {
  color: #777;
  font-size: 85%;
  padding: 0;

  li {
    list-style: none;
  }
}
