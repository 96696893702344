body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-dodo {
  color: #ffffff;
  background-color: #ff6900;
}

.btn-dodo:hover {
  color: #ffffff;
  background-color: #e55f00;
}

.nowrap {
  white-space: nowrap;
}

.text-white {
  color: #fff !important;
}

.max-300 {
  max-width: 300px;
}

.ml-20 {
  margin-left: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 550px;
    margin: 150px auto;
  }
}

.data-table-cell {
  text-align: right;
  padding-right: 30px !important;
}

.vault-header h2 {
  margin-top: 10px;
}

.vault-sub-header {
  font-size: 16px;
}

.cash-header a {
  color: #dd4b39;
  font-size: 60%;
  margin-left: 20px;
}

.cash-header a:hover {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}

.cash-header span {
  font-size: 80%;
}

.cash-left-pad {
  margin-left: 20px;
}

.cash-col-info {
  font-size: 16px;
}

.cash-col-info h1 {
  margin-bottom: 0;
}

.cash-col-info h1 span {
  color: #dd4b39;
  font-size: 60%;
}

.cash-col-info h2 {
  margin-bottom: 0;
}

.cash-col-info div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cash-col-table {
  font-size: 16px;
}

.cash-col-table th.cc-dodo {
  border-right: 1px solid #f4f4f4;
  text-align: right;
  padding: 8px 15px 8px 0;
}

.cash-col-table th.cc-z {
  padding: 8px 0 8px 15px;
  vertical-align: middle;
}

.cash-col-table td.cc-name {
  padding: 8px 0;
  vertical-align: middle;
  width: 220px;
}

.cash-col-table td.cc-dodo-revenue {
  border-right: 1px solid #f4f4f4;
  text-align: right;
  padding: 8px 15px 8px 0;
  vertical-align: middle;
  width: 100px;
}

.cash-col-table td.cc-edit-revenue {
  padding: 8px 0 8px 15px;
  vertical-align: middle;
  width: 100px;
}

.cash-col-table td.cc-edit-cancel {
  padding: 8px 0;
  vertical-align: middle;
  width: 85px;
}

.cash-col-table td.cc-diff {
  padding: 8px 0;
  vertical-align: middle;
  width: 120px;
}

.cash-col-table .cash-input {
  display: inline;
  width: 50px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 100%;
  padding: 0;
}

.collapsed-visible {
  display: none;
}

.collapsed-box .collapsed-visible {
  display: block;
}

.collapsed-box .collapsed-hidden {
  display: none;
}

.swal2-checklist {
  text-align: left;
}

.swal2-checklist .swal2-checkbox {
  display: flex;
  justify-content: left;
}

.audit-log {
  color: #777;
  font-size: 85%;
  padding: 0;
}

.audit-log li {
  list-style: none;
}
